@include margin-helper(8);
@include margin-helper(16);
@include margin-helper(20);
@include margin-helper(24);
@include margin-helper(32);
@include margin-helper(48);
@include padding-helper(0);
@include padding-helper(8);
@include padding-helper(16);
@include padding-helper(20);
@include padding-helper(24);
@include padding-helper(32);
@include padding-helper(48);

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-underline {
  text-decoration: underline;
}

.d-none {
  display: none !important;
}

.w100 {
  width: 100%;
}

.hidden {
  display: none !important;
}

.bold {
  font-weight: bold;
}