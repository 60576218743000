@mixin margin-helper($size) {
  .m-#{$size} {
    margin: calc($size / 16) + rem !important;
  }
  .mx-#{$size} {
    margin-left: calc($size / 16) + rem !important;
    margin-right: calc($size / 16) + rem !important;
  }
  .my-#{$size} {
    margin-top: calc($size / 16) + rem !important;
    margin-bottom: calc($size / 16) + rem !important;
  }
  .mt-#{$size} {
    margin-top: calc($size / 16) + rem !important;
  }
  .mr-#{$size} {
    margin-right: calc($size / 16) + rem !important;
  }
  .mb-#{$size} {
    margin-bottom: calc($size / 16) + rem !important;
  }
  .ml-#{$size} {
    margin-left: calc($size / 16) + rem !important;
  }
}

@mixin padding-helper($size) {
  .p-#{$size} {
    padding: calc($size / 16) + rem !important;
  }
  .px-#{$size} {
    padding-left: calc($size / 16) + rem !important;
    padding-right: calc($size / 16) + rem !important;
  }
  .py-#{$size} {
    padding-top: calc($size / 16) + rem !important;
    padding-bottom: calc($size / 16) + rem !important;
  }
  .pt-#{$size} {
    padding-top: calc($size / 16) + rem !important;
  }
  .pr-#{$size} {
    padding-right: calc($size / 16) + rem !important;
  }
  .pb-#{$size} {
    padding-bottom: calc($size / 16) + rem !important;
  }
  .pl-#{$size} {
    padding-left: calc($size / 16) + rem !important;
  }
}
