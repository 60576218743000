*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0; // 1
  -webkit-text-size-adjust: 100%; // 3
  -webkit-tap-highlight-color: transparent;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.15;
  text-align: left;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

p {
  margin-top: 0;
  margin-bottom: 2rem;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

// 1. Undo browser default

dd {
  margin-bottom: .5rem;
  margin-left: 0; // 1
}

blockquote {
  margin: 0 0 1rem;
}

// Add the correct font weight in Chrome, Edge, and Safari

b,
strong {
  font-weight: 700;
}


// Add the correct font size in all browsers

small {
  font-size: 0.8rem;
}

// Prevent `sub` and `sup` elements from affecting the line height in
// all browsers.

sub,
sup {
  position: relative;
  font-size: 0.5rem;
  line-height: 0;
  vertical-align: baseline;
}

sub { bottom: -.25em; }
sup { top: -.5em; }


// Links

// a {
//   color: $link-color;
//   text-decoration: $link-decoration;

//   &:hover {
//     color: $link-hover-color;
//     text-decoration: $link-hover-decoration;
//   }
// }

// And undo these styles for placeholder links/named anchors (without href).
// It would be more straightforward to just use a[href] in previous block, but that
// causes specificity issues in many other styles that are too complex to fix.
// See https://github.com/twbs/bootstrap/issues/19402

a:not([href]) {
  &,
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}


// Code

pre,
code,
kbd,
samp {
  font-family: monospace;
  font-size: 1rem;
}


// Figures

// Apply a consistent margin strategy (matches our type styles).

figure {
  margin: 0 0 1rem;
}


// Images and content

img {
  vertical-align: middle;
}

// 1. Workaround for the SVG overflow bug in IE 11 is still required.
//    See https://github.com/twbs/bootstrap/issues/26878

svg {
  overflow: hidden; // 1
  vertical-align: middle;
}


// Tables

// Prevent double borders

table {
  border-collapse: collapse;
}

// Matches default `<td>` alignment by inheriting from the `<body>`, or the
// closest parent with a set `text-align`.

th {
  text-align: inherit;
}


// Forms

// 1. Allow labels to use `margin` for spacing.

label {
  display: inline-block; // 1
  margin-bottom: 1rem;
}

// Remove the default `border-radius` that macOS Chrome adds.
//
// Details at https://github.com/twbs/bootstrap/issues/24093

button {
  // stylelint-disable-next-line property-blacklist
  border-radius: 0;
}

// Work around a Firefox/IE bug where the transparent `button` background
// results in a loss of the default `button` focus styles.
//
// Credit: https://github.com/suitcss/base/

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

// 1. Remove the margin in Firefox and Safari

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

// Show the overflow in Edge

button,
input {
  overflow: visible;
}

// Remove the inheritance of text transform in Firefox

button,
select {
  text-transform: none;
}

// Remove the inheritance of word-wrap in Safari.
//
// Details at https://github.com/twbs/bootstrap/issues/24990

select {
  word-wrap: normal;
}

// Remove the dropdown arrow in Chrome from inputs built with datalists.
//
// Source: https://stackoverflow.com/a/54997118

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Correct the inability to style clickable types in iOS and Safari.
// 3. Opinionated: add "hand" cursor to non-disabled button elements.

button,
[type="button"], // 1
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; // 2
}


// Remove inner border and padding from Firefox, but don't restore the outline like Normalize.

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}


// Remove the default appearance of temporal inputs to avoid a Mobile Safari
// bug where setting a custom line-height prevents text from being vertically
// centered within the input.
// See https://bugs.webkit.org/show_bug.cgi?id=139848
// and https://github.com/twbs/bootstrap/issues/11266

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: textfield;
}

// 1. Remove the default vertical scrollbar in IE.
// 2. Textareas should really only resize vertically so they don't break their (horizontal) containers.

textarea {
  overflow: auto; // 1
  resize: vertical;  // 2
}

// 1. Browsers set a default `min-width: min-content;` on fieldsets,
//    unlike e.g. `<div>`s, which have `min-width: 0;` by default.
//    So we reset that to ensure fieldsets behave more like a standard block element.
//    See https://github.com/twbs/bootstrap/issues/12359
//    and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
// 2. Reset the default outline behavior of fieldsets so they don't affect page layout.

fieldset {
  min-width: 0; // 1
  padding: 0;  // 2
  margin: 0; // 2
  border: 0; // 2
}


// 1. By using `float: left`, the legend will behave like a block element
// 2. Correct the color inheritance from `fieldset` elements in IE.
// 3. Correct the text wrapping in Edge and IE.
// Add the correct vertical alignment in Chrome, Firefox, and Opera.

progress {
  vertical-align: baseline;
}


// Fix height of inputs with a type of datetime-local, date, month, week, or time
// See https://github.com/twbs/bootstrap/issues/18842

::-webkit-datetime-edit {
  overflow: visible;
  line-height: 0;
}


// 1. Correct the outline style in Safari.
// 2. This overrides the extra rounded corners on search inputs in iOS so that our
//    `.form-control` class can properly style them. Note that this cannot simply
//    be added to `.form-control` as it's not specific enough. For details, see
//    https://github.com/twbs/bootstrap/issues/11586.

[type="search"] {
  outline-offset: -2px; // 1
  -webkit-appearance: textfield; // 2
}

// Remove the inner padding in Chrome and Safari on macOS.

::-webkit-search-decoration {
  -webkit-appearance: none;
}

// Remove padding around color pickers in webkit browsers

::-webkit-color-swatch-wrapper {
  padding: 0;
}

// 1. Change font properties to `inherit` in Safari.
// 2. Correct the inability to style clickable types in iOS and Safari.

::-webkit-file-upload-button {
  font: inherit; // 1
  -webkit-appearance: button; // 2
}

// Correct element displays

output {
  display: inline-block;
}

// 1. Add the correct display in all browsers

summary {
  display: list-item; // 1
  cursor: pointer;
}

// Add the correct display for template & main in IE 11

template {
  display: none;
}

main {
  display: block;
}

// Always hide an element with the `hidden` HTML attribute.

[hidden] {
  display: none !important;
}
