.sunroof-image {
  flex: 0 0 33.333333%;
  width: 33.333333%;

  &-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;

    .logo-sunroof {
      @media screen and (min-width: 768px) {
        height: 1.375rem;
      }
    }
  }

  &-main {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    svg {
      stroke: transparent;
      stroke-width: 0px;
      width: 100%;
      height: initial;

      image {
        stroke: transparent;
        stroke-width: 0px;
      }
    }
  }

  &-marker {
    position: absolute;
    width: 1.5rem;
    height: 2.625rem;
    top: calc(50% - 2.625rem);
    left: calc(50% - 0.75rem);
  }
}
